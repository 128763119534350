<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparan"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    {{ selectedItem }}
    <v-spacer />
    <template>
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              bordered
              color="red"
              overlap
            >
              <template v-slot:badge>
                <span>{{ notifications.meta.total }}</span>
              </template>

              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-list
          flat
          nav
        >
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="item in notifications.data"
              :key="item.id"
              @click="goTo(item.id)"
            >
              <v-list-item-content>
                <v-list-item-subtitle v-text="item.from.name" />
                <v-list-item-title v-text="item.text" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
    <div class="mx-3" />
    <v-switch
      v-model="$vuetify.theme.dark"
      prepend-icon="mdi-theme-light-dark"
      class="ma-0 pa-0"
      color="success"
      hide-details
      @click="toggle_dark_mode"
    />
    <base-notification @getMsg="getMsg" />
  </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from 'vuetify/lib'

// Utilities
  import firebase from 'firebase/app'
  import 'firebase/messaging'
  import { mapState, mapMutations } from 'vuex'
  import axios from 'axios'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')

  export default {
    name: 'DashboardCoreAppBar',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: {
        meta: {
          total: 0,
        },
        data: [
          {
            text: '',
            from: {
              name: '',
            },
          },
        ],
      },
      selectedItem: null,
    }),
    computed: {
      ...mapState(['drawer']),
    },
    created () {
      this.getMsg()
      var firebaseConfig = {
        apiKey: 'AIzaSyDz_P5luUH2TsTIiVdDw-Y7EoOZ7SpquCM',
        authDomain: 'notiv-community.firebaseapp.com',
        projectId: 'notiv-community',
        databaseURL: 'https://notiv-community.firebaseio.com',
        storageBucket: 'notiv-community.appspot.com',
        messagingSenderId: '802833483374',
        appId: '1:802833483374:web:c6bf207ed44fc73b70087f',
        measurementId: 'G-FXL09MSBQL',
      }

      // var firebaseConfig = {
      //   apiKey: 'AIzaSyAVazWZpKRdLWWTkKhCTbBQ2VSXMXDDCnU',
      //   authDomain: 'test-92fa9.firebaseapp.com',
      //   databaseURL: 'https://test-92fa9.firebaseio.com',
      //   projectId: 'test-92fa9',
      //   storageBucket: 'test-92fa9.appspot.com',
      //   messagingSenderId: '1010801819413',
      //   appId: '1:1010801819413:web:7727ae89d54008b1e3331c',
      //   measurementId: 'G-K3BH81L647',
      // }
      // Initialize Firebase
      // eslint-disable-next-line no-undef
      !firebase.apps.length
        ? firebase.initializeApp(firebaseConfig)
        : firebase.app()
      // firebase.analytics();
      // eslint-disable-next-line no-undef
      const messaging = firebase.messaging()
      messaging
        .requestPermission()
        .then(function () {
          // MsgElem.innerHTML = "Notification permission granted."
          console.log('Notification permission granted.7')

          // get the token in the form of promise

          return messaging.getToken()
        })
        .then(function (token) {
          // print the token on the HTML page
          axios
            .post('/v1/user/platform', {
              name: 'firebase',
              content: {
                app_url: 'https://report.notiva.net',
                token: token,
              },
              content_id: token,
              device_name: 'website',
            })
            .then(response => {
              console.log(response.data)
            })
          console.log(token)
        })
        .catch(function (err) {
          console.log('Unable to get permission to notify.', err)
        })

    // messaging.onMessage(function (payload) {
    //   console.log(payload) //
    //   // eslint-disable-next-line no-unused-vars
    //   // var notify
    //   // notify = new Notification(payload.notification.title, {
    //   //   body: payload.notification.body,
    //   //   icon: payload.notification.icon,
    //   //   tag: 'Dummy',
    //   // })
    //   console.log(payload.notification)
    // })
    },
    mounted () {
      const theme = localStorage.getItem('dark_theme')
      if (theme) {
        if (theme === 'true') {
          this.$vuetify.theme.dark = true
        } else {
          this.$vuetify.theme.dark = false
        }
      }
    },
    methods: {
      goTo (id) {
        axios.patch('/v1/user/message/' + id + '/read').then(response => {
          // update
          this.getMsg()
          //
          if (this.$route.path !== '/pesan') {
            this.$router.push({ path: '/pesan' })
          }
        })
      },
      setMsg (data) {
        this.notifications = data
      },
      getMsg () {
        axios.defaults.headers.common.Authorization =
          'Bearer ' + localStorage.getItem('access_token')
        axios
          .get(
            '/v1/user/message?inbox=1&filter[status][is]=unread&entities=from,to',
          )
          .then(({ data }) => {
            if (data.meta.status) {
              this.setMsg(data)
            }
          })
      },
      notification () {
        axios
          .post('/v1/user/platform', {
            name: 'firebase',
            content: {
              app_url: 'http://localhost:8082/',
              token:
                'cBOQiPOAJncaX0TC3kEumB:APA91bGbarKO6i8c1XJUHgtFPoVK3KGkE1XMqOvTskZB_ZLfK5O13uZyaoTPDu9f44KGK2LVNoistZiOZTXW-tfiV_aLNv4-5qGqkACSuR3MmTpzaQxpc86cpfof3CvnUCuI8oftPLIn',
            },
            notification: {
              body: 'Demo Notofikasi',
              title: 'Hi',
              receiver: 'User',
              icon: 'https://image.flaticon.com/icons/png/512/270/270014.png',
              sound: 'mySound',
            },
            content_id: '0',
            devive_name: 'website',
          })
          .then(response => {
            console.log(response)
          })
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      toggle_dark_mode: function () {
        localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString())
      },
    },
  }
</script>
